var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-submission-form" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v("\n    New Showcase Entry\n  "),
      ]),
      _vm._v(" "),
      _c(
        "FormRoot",
        {
          attrs: {
            "form-id": "showcase-submission",
            "enable-submit": !_vm.submitting,
            "submit-button-text": `${
              _vm.submitting ? "Submitting..." : "Share your work"
            }`,
          },
        },
        [
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        Your information\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "user-info" },
              [
                _c("TextInput", {
                  ref: "firstName",
                  attrs: {
                    model: _vm.firstName,
                    label: "First Name",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.firstName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "lastName",
                  attrs: {
                    model: _vm.lastName,
                    label: "Last Name",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.lastName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "email",
                  attrs: {
                    model: _vm.email,
                    label: "Email Address",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.email = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("legend", { staticClass: "subhead-2" }, [
                _vm._v("\n        Project details\n      "),
              ]),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "team",
                attrs: {
                  label: "Submitted By",
                  placeholder:
                    "Please add names and details of the colleagues who worked on this campaign",
                  model: _vm.formData.teamText,
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(_vm.formData, "teamText", $event)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "creator-wrapper" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Were you the primary designer/creator?"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "radio-option" },
                    [
                      _c("RadioInput", {
                        ref: "creator",
                        attrs: {
                          name: "creator",
                          model: _vm.formData.creator,
                          value: true,
                          label: "Yes",
                        },
                        on: {
                          "update:model": function ($event) {
                            return _vm.$set(_vm.formData, "creator", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radio-option" },
                    [
                      _c("RadioInput", {
                        ref: "creator",
                        attrs: {
                          name: "creator",
                          model: _vm.formData.creator,
                          value: false,
                          label: "No",
                        },
                        on: {
                          "update:model": function ($event) {
                            return _vm.$set(_vm.formData, "creator", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "producers",
                attrs: {
                  label: "Designers",
                  placeholder:
                    "Provide the name(s) of creative colleague, internal team or external vendor",
                  model: _vm.formData.producers,
                  "hide-asterisk": true,
                  required: "true",
                  validate: "true",
                },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(_vm.formData, "producers", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        Solution line or function\n      "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "caption" }, [
              _vm._v("\n        Check all that apply\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "responsive-2-columns" },
              [
                _vm.solutionLineList
                  ? _c("CheckboxArrayInput", {
                      attrs: {
                        name: "solutionLine",
                        model: _vm.formData.solutionLine,
                        list: _vm.solutionLineList,
                        required: "true",
                        validate: "true",
                      },
                      on: {
                        "update:model": function ($event) {
                          return _vm.$set(_vm.formData, "solutionLine", $event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        Region\n      "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "caption" }, [
              _vm._v("\n        Check all that apply\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "responsive-2-columns" },
              [
                _c("CheckboxArrayInput", {
                  attrs: {
                    name: "region",
                    model: _vm.regionSelection,
                    list: _vm.regionList,
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.regionSelection = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("TextInput", {
                ref: "country",
                attrs: {
                  model: _vm.formData.country,
                  label: "Country of Origin",
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(_vm.formData, "country", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("TextInput", {
                ref: "title",
                attrs: {
                  model: _vm.title,
                  label: "Project title",
                  "hide-asterisk": true,
                  required: "true",
                  validate: "true",
                  maxlength: _vm.maxTitleChars,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.title = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("TextareaInput", {
                ref: "description",
                attrs: {
                  label: "Project objective",
                  placeholder:
                    "Tell us about the objectives of the project and how it aligns to the Aon Story",
                  model: _vm.descriptionText,
                  "hide-asterisk": true,
                  required: "true",
                  validate: "true",
                  maxlength: _vm.maxDescriptionChars,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.descriptionText = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("TextareaInput", {
                ref: "insights",
                attrs: {
                  label: "Insights and inspiration",
                  placeholder:
                    "Tell us more about the thinking behind this project and any inspiration that helped bring it to life",
                  model: _vm.formData.insights,
                  "hide-asterisk": true,
                  required: "true",
                  validate: "true",
                  maxlength: _vm.maxInsightChars,
                },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(_vm.formData, "insights", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("TextareaInput", {
                ref: "impact",
                attrs: {
                  label: "Impact",
                  placeholder:
                    "Is there anything else you would like to tell us about your project (purpose, challenges, key learnings, outcomes, outputs, etc.)?",
                  model: _vm.formData.aon_impact,
                  "hide-asterisk": true,
                  maxlength: _vm.maxImpactChars,
                },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(_vm.formData, "aon_impact", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("label", { staticClass: "form-label" }, [_vm._v("Audience")]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                { staticClass: "radio-option" },
                [
                  _c("RadioInput", {
                    ref: "audience",
                    attrs: {
                      name: "audience",
                      model: _vm.formData.audience,
                      value: "external",
                      label: "External",
                    },
                    on: {
                      "update:model": function ($event) {
                        return _vm.$set(_vm.formData, "audience", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "radio-option" },
                [
                  _c("RadioInput", {
                    ref: "audience",
                    attrs: {
                      name: "audience",
                      model: _vm.formData.audience,
                      value: "internal",
                      label: "Internal",
                    },
                    on: {
                      "update:model": function ($event) {
                        return _vm.$set(_vm.formData, "audience", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        Project deliverables\n      "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "caption" }, [
              _vm._v("\n        Check all that apply\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "responsive-2-columns" },
              [
                _c("CheckboxArrayInput", {
                  attrs: {
                    name: "deliverable",
                    model: _vm.formData.deliverables,
                    list: _vm.deliverableList,
                  },
                  on: {
                    "update:model": function ($event) {
                      return _vm.$set(_vm.formData, "deliverables", $event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("legend", { staticClass: "subhead-2" }, [
                _vm._v("\n        Upload images or assets here\n      "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "caption" }, [
                _vm._v("\n        Maximum 10 assets\n      "),
              ]),
              _vm._v(" "),
              _c("ShowcaseFileUpload", {
                ref: "fileUpload",
                attrs: {
                  "upload-button-text": "Add File(s)",
                  "upload-files-demo": "uploadFilesDemo",
                },
                on: { "send-hash-collection": _vm.onHashCollectionReady },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("showcase-links", {
                ref: "showcaseLinks",
                attrs: {
                  links: _vm.links,
                  placeholder_text: "Link title",
                  placeholder_url: "Link url",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }