var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.submission
    ? _c("div", { staticClass: "client-submission-info", class: _vm.classes }, [
        _vm.submissionData.teamText
          ? _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n      Submitted By:\n    "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "info-content" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.submissionData.teamText) + "\n    "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.submissionData.producers
          ? _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n      Designers:\n    "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "info-content" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.submissionData.producers) + "\n    "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.solutionLine
          ? _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n      Solution Line:\n    "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "info-content" }, [
                _vm._v("\n      " + _vm._s(_vm.solutionLine) + "\n    "),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.regions
          ? _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n      Region:\n    "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "info-content" }, [
                _vm._v("\n      " + _vm._s(_vm.regions) + "\n    "),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.submissionData.country
          ? _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n      Country of Origin:\n    "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "info-content" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.submissionData.country) + "\n    "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.submissionData.audience
          ? _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n      Audience:\n    "),
              ]),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.submissionData.audience,
                    expression: "submissionData.audience",
                  },
                ],
                staticClass: "info-content",
                style: { textTransform: "capitalize" },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.deliverables
          ? _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n      Deliverables:\n    "),
              ]),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.deliverables,
                    expression: "deliverables",
                  },
                ],
                staticClass: "info-content",
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.links.length > 0
          ? _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "info-title" }, [
                _vm._v("\n      Related Links:\n    "),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "links" },
                _vm._l(_vm.links, function (link, index) {
                  return _c("li", { key: index }, [
                    _c("a", { attrs: { href: link.url, target: "_blank" } }, [
                      _vm._v(_vm._s(link.text)),
                    ]),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }